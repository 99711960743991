import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router";

export const AdminCustomersComponent = () => {
    const navigate = useNavigate();

    return (
        <div>
            <Button className={'secondary-btn'}
                    type="primary"
                    onClick={() => navigate('../customer-edit')}>
                Add Customer</Button>
        </div>
    );
}