import { Route, Routes } from "react-router";
import React from "react";
import { AdminSharedApnsComponent } from "./admin-shared-apns/admin-shared-apns";
import { AdminCustomersComponent } from "./admin-customers/admin-customers";
import { CustomerEditComponent } from "./admin-customers/customer-edit";
import './admin-content.scss'

export const AdminContentComponent = () => {

    return (
        <div className='admin-content'>
            <Routes>
                <Route path='customers' element={<AdminCustomersComponent/>}/>
                <Route path='customer-edit' element={<CustomerEditComponent/>}/>
                <Route path='shared-apns' element={<AdminSharedApnsComponent/>}/>
            </Routes>
        </div>
    );
}