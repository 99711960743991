import { observer } from 'mobx-react-lite';
import './customer-edit.scss';
import { Button, Collapse, CollapseProps } from "antd";
import React from "react";
import { ReactComponent as CollapseIcon } from '../../../../assets/svg/chevron-down-circle.svg';
import { CustomerDetailsComponent } from "./customer-details/customer-details";
import { ControlCenterAccountsComponent } from "./cc-accounts/cc-acounts";
import { ApnsComponent } from "./apns/apns";

export const CustomerEditComponent = observer(() => {

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'Customer details',
            children: <CustomerDetailsComponent/>,

        },
        {
            key: '2',
            label: 'Control Center accounts',
            children: <ControlCenterAccountsComponent/>,
        },
        {
            key: '3',
            label: 'APNs',
            children: <ApnsComponent/>,
        },
    ];

    return <div className='details-container'>
        <div className='details-header'>
            <div className='header-title'>Add customer</div>
            <div className='header-buttons'>
                <Button className={'secondary-btn'} type="primary"
                        onClick={() => console.log(`Cancel`)}> Cancel</Button>
                <Button className={'secondary-btn'} type="primary"
                        onClick={() => console.log(`Save Draft`)}> Save Draft</Button>
                <Button type="primary"
                        onClick={() => console.log(`Create Customer`)}> Create Customer</Button>
            </div>
        </div>
        <div>
            <Collapse items={items} defaultActiveKey={['1', '2', '3']} bordered={false}
                      expandIcon={({isActive}) => (
                          <CollapseIcon style={{
                              transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                              transition: 'transform 0.2s'
                          }}/>
                      )}/>;
        </div>
    </div>;
});